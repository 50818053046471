import './layout.css'

export function LandingSection({ children, background, className }) {

    return (
        <div className={`landing-section ${className}`} style={{ backgroundColor: background ? background : "white" }}>
            <div className='section-content'>
                {children}
            </div>
        </div>
    )
}