import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import { ReactComponent as GoogleSVG } from '../icons/google.svg';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { storeUser } from '../util/UserUtil';
import { ReactComponent as LoaderSVG } from '../icons/loader-ball-triangle.svg';
import pushListener from '../evt/PushListener';
import AuthAPI from '../api/AuthAPI';
import { ATScreen } from '../comp/layout/ATScreen';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import LoginBox from './parts/login/LoginBox';

const cookies = new Cookies();

class LoginScreen extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (

            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <LoginBox />
            </div>
        )
    }
}

export default withSingleColumnsLayout(withRouter(withTranslation()(LoginScreen)))  