import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import './UserActivatedScreen.css';
import NPButton from '../comp/NPButton';
import { withRouter } from 'react-router';
import { ReactComponent as ActivatedIllustration } from '../images/activated-illustration.svg';
import { ReactComponent as ActivationFailedIllustration } from '../images/activation-failed-illustration.svg';
import Footer from '../Footer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthAPI from '../api/AuthAPI';
import PleggitAnimatedLoader from '../comp/PleggitAnimatedLoader';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import { LandingSection } from '../comp/layout/LandingSection';
import { ResponsiveRow } from '../comp/layout/ResponsiveRow';

function UserActivatedScreen() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const queryParams = new URLSearchParams(window.location.search);
    const activationCode = queryParams.get('activationCode');

    const activateAccount = async () => {

        const timer = setTimeout(() => { setLoading(true) }, 500)

        const activationResult = await new AuthAPI().activateAccount(activationCode);

        clearTimeout(timer);
        setLoading(false)

        if (activationResult && activationResult.validCode === false) setError({ validCode: false })
        else setError(null)

    }

    useEffect(() => { activateAccount() }, [])

    return (
        <div className="screen">

            <LandingSection className="landing-create-profile">
                <ResponsiveRow align="flex-start">
                    <div className="user-activated-screen">

                        <RegisteredComponent loading={loading} error={error} />

                        <div className="activation-image">
                            {loading && <div style={{ transform: "scale(2.5)" }}><PleggitAnimatedLoader /></div>}
                            {!loading && !error && <ActivatedIllustration />}
                            {!loading && error && <ActivationFailedIllustration />}
                        </div>
                    </div>
                </ResponsiveRow>
            </LandingSection>

        </div>
    )
}

function RegisteredComponent({ partyType, loading, error }) {

    const history = useHistory()

    const goToLogin = () => {
        history.push("/login")
    }

    let title = "Registrazione effettuata!"
    let desc1 = "La registrazione è andata a buon fine."
    let desc2 = <p>Puoi ora accedere a Pleggit, verificare i dati della tua transazione e <b>completare il tuo Profilo Pleggit</b> per proseguire!</p>

    if (loading) {
        title = "Registrazione in corso..."
        desc1 = <p>Stiamo attivando la tua utenza.</p>
        desc2 = <p>Fra poco potrai accedere a Pleggit, verificare i dati della tua transazione e <b>completare il tuo Profilo Pleggit</b>!</p>
    }
    else if (error && error.validCode == false) {
        title = "Registrazione fallita."
        desc1 = <p>L'attivazione non è andata a buon fine.</p>
        desc2 = <p>Per favore riprova la procedura fra aualche minuto oppure contatta la tua agenzia di riferimento per effettuare una nuova registrazione.</p>
    }

    return (
        <div className="registered-component">
            <div className="title"><b>{title}</b></div>
            <div className="vspacing"></div>
            <div className="text sidebar">
                {desc1}
                {desc2}
            </div>
            <div className="vspacing"></div>
            {!loading && !error && <div style={{ display: "flex" }}><NPButton label="Accedi a Pleggit" onClick={goToLogin} /></div>}
            <div className="vspacing x2"></div>
        </div>
    )
}

export default withSingleColumnsLayout(withRouter(withTranslation()(UserActivatedScreen)))