import { LabeledValue, Tag } from "./DashboardElements";
import './CustomerTransactions.css'
import NPIconButton from "../../../comp/NPIconButton";
import Popup from 'reactjs-popup';
import { ReactComponent as CarSVG } from '../../../icons/car.svg'
import { ReactComponent as KeySVG } from '../../../icons/car-key.svg'
import { ReactComponent as HandsSVG } from '../../../icons/handshake.svg'
import { ReactComponent as CloseSVG } from '../../../icons/close.svg'

import Cookies from "universal-cookie";
import { TransactionStateIcon } from "../../../comp/TransactionStateIcon";
import { useState } from "react";
import NPCard from "../../../comp/card/NPCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NPLoadingBar } from "../../../comp/NPLoadingBar";
import { NPCardMessage } from "../../../comp/card/NPCardMessage";
import { isBuyer, isSeller } from "../../../util/UserUtil";

const cookies = new Cookies()

export function CustomerTransactions({ transactions, loading }) {

    if (loading) return (
        <NPLoadingBar message="Caricamento transazioni in corso.." />
    )

    if (!transactions || transactions.length == 0) return (
        <NPCardMessage text="Non ci sono ancora transazioni di compravendita" />
    )

    return (
        <div className="">
            {transactions && transactions.map(
                (tx) => <CustomerTransaction transaction={tx} key={Math.random()} />
            )}
        </div>
    )
}


export function CustomerTransaction({ transaction }) {

    const user = cookies.get('user')

    const history = useHistory()

    /**
     * Navigate to the transaction detail
     */
    const goToTransactionDetail = () => {

        history.push("/transaction", { tid: transaction.id })
    }

    return (
        <div className="customer-transaction" onClick={goToTransactionDetail}>
            <div className="cell" style={{ flex: 1, minWidth: "40%" }}>
                <LabeledValue label="Oggetto compravendita" value={transaction.adTitle} />
            </div>
            <div className="cell" style={{ flex: 1, minWidth: "20%" }}>
                <LabeledValue label="Prezzo concordato" value={transaction.price} type="currency" currency={transaction.currency} />
            </div>
            <div className="cell" style={{ flex: 1, minWidth: "15%" }}>
                <LabeledValue label="Commissioni" value={isBuyer(transaction) ? transaction.buyerFee : transaction.sellerFee} type="currency" currency={transaction.currency} />
            </div>
            <div className="cell" style={{ minWidth: "15%" }}>
                {isBuyer(transaction) && transaction.buyerPaidOn == null && <Tag content="compra" />}
                {isBuyer(transaction) && transaction.buyerPaidOn != null && transaction.sellerConfirmedPTOn != null && transaction.buyerConfirmedPTOn == null && <Tag content="ricevuto" />}
                {isSeller(transaction) && transaction.buyerPaidOn != null && transaction.sellerConfirmedPTOn == null && <Tag content="spedisci" />}
                {isSeller(transaction) && transaction.buyerPaidOn != null && transaction.sellerConfirmedPTOn != null && transaction.buyerConfirmedPTOn != null && transaction.sellerPaidOn == null && <Tag content="ricevi fondi" />}
            </div>
            <div className="cell" style={{ minWidth: "5%" }}>
                <TransactionStateIcon transaction={transaction} />
            </div>
        </div>
    )
}


export function CustomerTransactionLegend() {

    const [showLegend, setShowLegend] = useState(false)

    return (

        <div className="customer-tx-legend">
            <LegendButton label={showLegend ? "Nascondi Leggenda" : "Leggenda icone"} onLegendClick={() => { setShowLegend(!showLegend) }} />

            <Popup open={showLegend} onClose={() => { setShowLegend(false) }}>
                <NPCard>
                    <div className="customer-tx-legend-popup">
                        <div className="card-header">
                            <div className="card-title">Leggenda Stati Transazione</div>
                            <div>
                                <NPIconButton image={<CloseSVG />} onClick={() => { setShowLegend(false) }} />
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><CarSVG /></div>
                            <div className="text">
                                <div className="title">E' possibile procedere con la consegna del bene.</div>
                                <div className="desc">I fondi per la transazione sono stati ricevuti da Pleggit e sono al sicuro. Le due parti possono procedere con la consegna del bene. </div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><KeySVG /></div>
                            <div className="text">
                                <div className="title">Il bene è stato consegnato.</div>
                                <div className="desc">Il bene è stato effettuato consegnato dal Venditore al Compratore. Il Compratore ha confermato di aver ricevuto il bene.</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="icon"><HandsSVG /></div>
                            <div className="text">
                                <div className="title">La compravendita è conclusa.</div>
                                <div className="desc">Il passaggio di proprietà è stato effettuato e i fondi della transazione sono ancora stati versati al Venditore. La transazione di compravendita è conclusa.</div>
                            </div>
                        </div>
                    </div>
                </NPCard>
            </Popup>

        </div>

    )
}

function LegendButton({ label, onLegendClick }) {

    return (
        <div className="legend-button" onClick={onLegendClick}>
            {label}
        </div>
    )

}