import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { LandingSection } from "../../../comp/layout/LandingSection"
import { HSpacer } from "../../../comp/layout/HSpacer"
import { ResponsiveRow } from "../../../comp/layout/ResponsiveRow"
import { AdBox } from "./AdBox"
import AffareTrattoreAPI from "../../../api/AffareTrattoreAPI"
import { NPLink } from "../../../comp/NPLink"

export function LandingRegistered({ noAd }) {

    const [idAd, setIdAd] = useState()
    const [price, setPrice] = useState()
    const [adTitle, setAdTitle] = useState()
    const [adImageURL, setAdImageURL] = useState()
    const [loadingAd, setLoadingAd] = useState()

    const location = useLocation()

    /**
     * Load the Marketplace Ad
     */
    const loadAdData = async () => {

        const idAd = new URLSearchParams(location.search).get('id')

        if (!idAd) return;

        setIdAd(idAd)

        setLoadingAd(true)

        const adDetails = await new AffareTrattoreAPI().getAdDetails(idAd);

        setLoadingAd(false)

        if (adDetails) {
            setAdTitle(adDetails.title)
            setPrice(adDetails.price)
            setAdImageURL(new AffareTrattoreAPI().getAdImageProxiedUrl(adDetails.image))
        }

    }

    useEffect(() => { loadAdData() }, [])

    return (
        <div className="landing-registered">
            <LandingSection className="landing-create-profile">
                <div className="title">Registrazione completata!</div>
                <HSpacer size="large" />
                <ResponsiveRow align="flex-start">
                    <div className="form">
                        <p>Ti abbiamo inviato una mail di conferma all'indirizzo email indicato.</p>
                        <p>Segui le istruzioni per confermare la registrazione e <NPLink text="effettua la login" href="/" /> per procedere con la compravendita.</p>
                    </div>
                    {!noAd &&
                        <div className='ad-container'>
                            <AdBox loading={loadingAd} title={adTitle} imageUrl={adImageURL} price={price} />
                        </div>
                    }
                </ResponsiveRow>
                <HSpacer size="x-large" />
            </LandingSection>
        </div>
    )
}