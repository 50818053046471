import { useEffect, useState } from 'react'
import NPCard from '../NPCard'
import './PropertyTransferCard.css'
import { NPCardMessage } from '../NPCardMessage'
import { NPSquareButton } from '../../NPSquareButton'
import { ReactComponent as ButtonIconSVG } from '../../../icons/car-key.svg';
import { ReactComponent as CloseSVG } from '../../../icons/close.svg';
import MarketplaceAPI from '../../../api/MarketplaceAPI'
import Cookies from 'universal-cookie'
import NPIconButton from '../../NPIconButton'
import Popup from 'reactjs-popup'
import { OTPTxConfirmationPopup } from '../../OTPTxConfirmationPopup'
import ProfileAPI from '../../../api/ProfileAPI'
import { NPLoadingBar } from '../../NPLoadingBar'
import { isBuyer, isSeller } from '../../../util/UserUtil'
import { HSpacer } from '../../layout/HSpacer'
import NPTextInput from '../../../forms/NPTextInput'
import { NPRadioButtons } from '../../../forms/NPRadioButtons'
import NPButton from '../../NPButton'
import { NPPhone } from '../../../forms/NPPhone'

const cookies = new Cookies()

const deliveryOptions = [
    { code: "manual", label: "Ho consegnato personalmente il bene" },
    { code: "carrier", label: "Ho spedito il bene" }
]

export function PropertyTransferCard({ tx, onPropertyTransferConfirmed }) {

    const [loading, setLoading] = useState(true)
    const [transaction, setTransaction] = useState(tx)
    const [showOTPConfirmation, setShowOTPConfirmation] = useState(false)
    const [profile, setProfile] = useState();
    const [deliveryType, setDeliveryType] = useState()
    const [carrier, setCarrier] = useState()
    const [carrierPhoneCountryCode, setCarrierPhoneCountryCode] = useState('+39')
    const [carrierPhone, setCarrierPhone] = useState()
    const [trackingId, setTrackingId] = useState()

    /**
     * Loads the user profile
     */
    const loadProfile = async () => {

        const timer = setTimeout(() => { setLoading(true) }, 400)

        const profile = await new ProfileAPI().getProfileData()

        setProfile(profile)

        clearTimeout(timer)
        setLoading(false)
    }

    /**
     * When the property transfer has been confirmed by the user
     */
    const onPropertyTransferred = () => {

        setShowOTPConfirmation(false);

        if (onPropertyTransferConfirmed) onPropertyTransferConfirmed();

    }

    useEffect(() => { loadProfile() }, [])

    if (loading) return (<NPCard><NPLoadingBar /></NPCard>)

    // DO NOT SHOW this Card if... 
    if (tx.buyerPaidOn == null) return <></>

    // If the user is the buyer and has confirmed the PT
    if (isBuyer(tx) && tx.buyerConfirmedPTOn != null) {
        // If the seller has also confirmed it
        if (tx.sellerConfirmedPTOn != null) return (
            <NPCard>
                <NPCardMessage noBorder={true} icon="ok" date={transaction.buyerConfirmedPTOn} text={`Hai concluso la transazione! Grazie per aver usato Pleggit!`} />
            </NPCard>
        )
        // Otherwise (seller has not yet confirmed it)
        else return (
            <NPCard>
                <NPCardMessage text={<>Hai confermato la conclusione della compravendita. <br />Siamo ora in attesa della conferma della tua Controparte per chiudere la transazione.</>} />
            </NPCard>
        )

    }

    // If the user is the seller and has confirmed the PT
    if (isSeller(tx) && tx.sellerConfirmedPTOn != null) {
        // If the buyer has also confirmed it
        if (tx.buyerConfirmedPTOn != null) return (
            <NPCard>
                <NPCardMessage noBorder={true} icon="ok" date={transaction.sellerConfirmedPTOn} text={<>Il Compratore ha confermato di aver ricevuto il bene!</>} />
            </NPCard>
        )
        // Otherwise (buyer has not yet confirmed it)
        else return (
            <NPCard>
                <NPCardMessage text={<>Siamo in attesa della conferma della tua Controparte per svincolare i fondi.</>} />
            </NPCard>
        )
    }

    return (
        <NPCard>
            <div className='agency-pt-card row'>
                {isBuyer(tx) &&
                    <>
                        <NPCardMessage noBorder={true} icon="action" text="Hai ricevuvto l'oggetto della compravendita?" instructions="Completa la transazione confermando la ricezione del bene." />
                        <HSpacer />

                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <NPButton label="Ho ricevuto il bene" onClick={() => { setShowOTPConfirmation(true) }} />
                        </div>
                    </>
                }
                {isSeller(tx) &&
                    <>
                        <NPCardMessage noBorder={true} icon="action" text="Hai consegnato l'oggetto della compravendita al Compratore?" instructions="Completa la transazione fornendoci le seguenti informazioni sulla consegna." />
                        <HSpacer />

                        <NPRadioButtons label="Come hai consegnato il bene?" options={deliveryOptions} selectedOption={deliveryType} onSelectOption={(o) => { setDeliveryType(o) }} />
                        {deliveryType && deliveryType.code == 'carrier' &&
                            <>
                                <NPTextInput label="Quale Corriere hai utilizzato per la spedizione?" placeholder="Inserisci il Corriere" mandatory={true} onChange={(v) => { setCarrier(v) }} />
                                <NPPhone label="Numero di Telefono del Corriere" countryCodeValue={carrierPhoneCountryCode} phoneValue={carrierPhone} onChangeCountryCode={(v) => { setCarrierPhoneCountryCode(carrierPhoneCountryCode) }} onChangePhoneNumber={(v) => { setCarrierPhone(v) }} />
                                <NPTextInput label="Tracking ID per la spedizione (opzionale)" placeholder="Inserisci il Tracking ID" mandatory={false} onChange={(v) => { setTrackingId(v) }} />
                            </>
                        }
                        <HSpacer />

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <NPButton label="Confermo la Consegna" onClick={() => { setShowOTPConfirmation(true) }} disabled={!deliveryType || (deliveryType.code == 'carrier' && (!carrier || !carrierPhoneCountryCode || !carrierPhone))} />
                        </div>
                    </>
                }
            </div>

            <Popup open={showOTPConfirmation} onClose={() => { setShowOTPConfirmation(false) }}>
                <OTPTxConfirmationPopup tid={transaction.id} carrierInfo={{ carrier: carrier, trackingId: trackingId, carrierPhoneCountryCode: carrierPhoneCountryCode, carrierPhone: carrierPhone }} profile={profile} onClose={() => { setShowOTPConfirmation(false) }} onPropertyTransfered={onPropertyTransferred} />
            </Popup>

        </NPCard>
    )
}