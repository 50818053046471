import './IdentityCardExample.css'
import passport from '../../../images/docs/passport.png';
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow';

export function PassportExample() {
    return (
        <div className='id-card-example'>
            <ResponsiveRow align="center" justify="center">
                <img src={passport} />
            </ResponsiveRow>
        </div>
    )
}
