import './CarrierInfoCard.css'
import { HSpacer } from "../../layout/HSpacer";
import NPLabeledValue from "../../NPLabeledValue";
import NPCard from "../NPCard";
import { NPCardMessage } from "../NPCardMessage";
import { isBuyer, isSeller } from '../../../util/UserUtil';

export function CarrierInfoCard({ transaction }) {

    console.log(transaction);

    return (
        <NPCard>
            {
                transaction.sellerConfirmedPTOn != null && transaction.carrier &&
                <>
                    {isBuyer(transaction) && <NPCardMessage icon="carrier" noBorder={true} text="Il Venditore ha indicato di aver spedito il bene." instructions="Di seguito puoi trovare le indicazioni sul Corriere." date={transaction.sellerConfirmedPTOn} />}
                    {isSeller(transaction) && <NPCardMessage icon="carrier" noBorder={true} text="Hai indicato di aver spedito il bene al Compratore." instructions="Queste sono le informazioni inserite sul Corriere." date={transaction.sellerConfirmedPTOn} />}
                    <HSpacer />
                    <div className="carrier-info">
                        <NPLabeledValue label="Corriere" value={transaction.carrier} />
                        <NPLabeledValue label="Telefono Corriere" value={`${transaction.carrierPhoneCountryCode} ${transaction.carrierPhone}`} />
                        {transaction.trackingId && <NPLabeledValue label="Tracking ID" value={transaction.trackingId} />}
                    </div>
                </>
            }
            {transaction.sellerConfirmedPTOn != null && !transaction.carrier &&
                <>
                    {isBuyer(transaction) && <NPCardMessage icon="carrier" noBorder={true} text="Il Venditore ha indicato di averti personalmente consegnato il bene." date={transaction.sellerConfirmedPTOn} />}
                    {isSeller(transaction) && <NPCardMessage icon="carrier" noBorder={true} text="Hai indicato di aver personalmente consegnato il bene al Compratore." date={transaction.sellerConfirmedPTOn} />}
                </>
            }
        </NPCard>
    )

}