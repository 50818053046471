import './NPRadioButtons.css';
import { ReactComponent as TickSVG } from '../icons/tick.svg';

/**
 * This is a controlled component that displays radio buttons
 * 
 * @param {string} label    the label of the input
 * 
 * @param {object} options  must be an array of Option objects. 
 *                          An Option is {code: string, label: string} 
 * 
 * @param {function} onSelectOption callback that needs to handle the event of an option being selected
 * 
 * @param {object} selectedOption specifies (if any) which option has been selected
 */
export function NPRadioButtons({ label, options, onSelectOption, selectedOption, row }) {

    return (
        <div className="np-radio-buttons">
            <div className='label'>{label}</div>
            <div className={`${row ? "row" : "column"}`}>
                {options && options.map(
                    (option) => <RadioButton option={option} key={Math.random()} selected={selectedOption && selectedOption.code == option.code} onClick={() => { onSelectOption(option) }} />
                )}
            </div>
        </div>
    )

}

function RadioButton({ option, selected, onClick }) {

    return (
        <div className="option" onClick={onClick}>
            <div className="radio">
                {selected && <TickSVG />}
            </div>
            <div className='label'>{option.label}</div>
        </div>
    )
}