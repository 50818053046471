import * as config from '../Config'
import NPAPI from './NPAPI';

export default class AffareTrattoreAPI {

  async getAdDetails(adId) {

    return new NPAPI().fetch('mkt', `/ads/${adId}`).then((response) => response.json());

  }

  getAdImageProxiedUrl(imageUrl) {

    return `${config.APIS.mkt}/adImage?url=${imageUrl}`

  }

}
