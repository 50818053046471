import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './NPLabeledValue.css';
import moment from 'moment-timezone';
import { ReactComponent as TickSVG } from '../icons/tick-circle.svg';
import { ReactComponent as WarnSVG } from '../icons/exclamation-circle.svg';
import { ReactComponent as CopySVG } from '../icons/copy.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';

/**
 * Display a label and value. 
 * Typically used to display records values. 
 * 
 * Parameters
 * 
 *  - value             :   (MAND) the value
 *  - label             :   (MAND) the label 
 *  - type              :   (OPT, default 'string'). The type of the value. Can be 'string', 'date', 'number', 'currency', 'datetime-nospace', 'timestamp-hour'
 *                          datetime-nospace - needs to be formatted YYYYMMDDHHmmss 20210625210313
 *  - marginVertical    :   (OPT) vertical margin
 *  - checkmark         :   (OPT, defailt false) - Boolean - Display a green checkmark
 *  - warnIfNoCheckmark :   (OPT, default false) Puts a warning sign if there is no checkmark
 *  - warning           :   (OPT, defailt false) - Boolean - Display a warning sign
 *  - copy              :   (OPT, default false) show a copy to clipboard icon
 *  - size              :   (OPT, default normal) Size of the text. Can be "normal", "large"
 *  - textAlign         :   (OPT) text alignment: pass "center" to align centrally
 *  - transform         :   (OPT, default null) accepts any "CSS text transform" property
 */
class NPLabeledValue extends Component {

    constructor(props) {
        super(props);

        this.state = {
            copied: false
        }

        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.props.value);
        this.setState({ copied: true }, () => {
            setTimeout(() => {
                this.setState({ copied: false })
            }, 100);
        })
    }

    render() {

        const { t } = this.props;

        let value = this.props.value;
        if (value && this.props.type == 'date') value = moment(this.props.value, 'YYYYMMDD').format('DD.MM.YYYY');
        if (value && this.props.type == 'timestamp-hour') value = moment(this.props.value, 'YYYYMMDDHHmmss').format('HH:mm');
        if (value && this.props.type == 'datetime-nospace') value = moment(this.props.value, 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm:ss');
        if (value && this.props.type == 'number') value = value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        if (value != null && this.props.type == 'currency') value = value.toLocaleString('it-IT', { style: 'currency', currency: this.props.currency ? this.props.currency : "EUR", minimumFractionDigits: 2 })

        let baseClass = "np-labeled-value";
        if (this.props.textAlign) baseClass += ' ' + this.props.textAlign;

        let style = {}
        if (this.props.marginVertical) style = { ...style, marginTop: this.props.marginVertical, marginBottom: this.props.marginVertical }
        if (this.props.marginBottom) style = { ...style, marginBottom: this.props.marginBottom }

        // Checkmark
        let checkmark;
        if (this.props.checkmark == true) checkmark = (
            <div className="checkmark">
                <Tippy content={t('tooltip.field.verified')} theme="light">
                    <TickSVG />
                </Tippy>
            </div>
        )
        // Otherwise, check the warnIfNoCheckmark field
        else if (this.props.warnIfNoCheckmark == true || this.props.warning === true) checkmark = (
            <div className="checkmark">
                <Tippy content={t('tooltip.field.not.verified')} theme="light">
                    <WarnSVG className="complementary" />
                </Tippy>
            </div>
        )

        // Copy To Clipboard
        let copyToClipboard;
        if (this.props.copy) copyToClipboard = (
            <div className="checkmark" style={{ cursor: 'pointer', color: 'var(--color-accent)' }} onClick={this.copyToClipboard}>
                <Tippy content={t('tooltip.copy.to.clipboard')} theme="light">
                    <CopySVG />
                </Tippy>
            </div>
        )

        // Tooltip type
        let tooltipType = 'info';
        if (this.props.checkmark == false && this.props.warnIfNoCheckmark) tooltipType = 'warning';

        // Value class
        let valueClass = 'value focused-easeInOut-motion';
        if (this.state.copied) valueClass += ' copy-animation';
        if (this.props.size) valueClass += ' ' + this.props.size;
        if (this.props.textAlign) valueClass += ' ' + this.props.textAlign;

        return (
            <div className={baseClass} style={style}>
                <div className="label font-small">{t(this.props.label)}</div>
                <div className="value-container">
                    <div className={valueClass} style={{textTransform: this.props.transform}}>{value != null ? value : 'N.D.'}</div>
                    {copyToClipboard}
                    {checkmark}
                </div>
            </div>
        )
    }
}

export default withTranslation()(NPLabeledValue);