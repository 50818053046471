import { useState } from "react";
import NPCard from "../NPCard";
import { NPCardMessage } from "../NPCardMessage";
import NPTextInput from "../../../forms/NPTextInput";
import IBAN from "iban";
import { NPCheckBox } from "../../NPCheckBox";
import { HSpacer } from "../../layout/HSpacer";
import NPButton from "../../NPButton";
import { isBuyer } from "../../../util/UserUtil";
import MarketplaceAPI from "../../../api/MarketplaceAPI";
import { NPLoadingBar } from "../../NPLoadingBar";
import NPLabeledValue from "../../NPLabeledValue";

export function SellerPaidCard({ transaction, onIBANSaved }) {

    // Cases in which the card should not be shown
    if (transaction.sellerPaidOn == null) return <></>

    return (
        <NPCard>
            <NPCardMessage
                icon="money"
                noBorder={true}
                date={transaction.sellerPaidOn}
                text={<><b>Abbiamo effettuato il bonifico</b> all'IBAN da te indicato.</>}
                instructions={<>Dovresti ricevere i fondi (meno l'importo delle commissioni) entro <b>5 giorni lavorativi</b> dalla data indicata qui sopra.<br/>IBAN a cui è stato inviato l'importo della transazione: {transaction.iban}</>}
            />
        </NPCard>
    )

}