import './IdentityCardExample.css'
import ciElFront from '../../../images/docs/ci-el-front.png';
import ciElBack from '../../../images/docs/ci-el-back.png';
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow';

export function IdentityCardExampleElFront() {
    return (
        <div className='id-card-example'>
            <ResponsiveRow align="center" justify="center">
                <img src={ciElFront} />
            </ResponsiveRow>
        </div>
    )
}

export function IdentityCardExampleElBack() {
    return (
        <div className='id-card-example'>
            <ResponsiveRow align="center" justify="center">
                <img src={ciElBack} />
            </ResponsiveRow>
        </div>
    )
}