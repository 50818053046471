import './OTPTxConfirmationPopup.css'
import NPCard from './card/NPCard'
import NPIconButton from './NPIconButton'
import { ReactComponent as CloseSVG } from '../icons/close.svg';
import { NPCardMessage } from './card/NPCardMessage';
import { NPPhone } from '../forms/NPPhone';
import { HSpacer } from './layout/HSpacer';
import NPButton from './NPButton';
import { useState } from 'react';
import ProfileAPI from '../api/ProfileAPI';
import NPTextInput from '../forms/NPTextInput';
import { ResponsiveRow } from './layout/ResponsiveRow';
import MarketplaceAPI from '../api/MarketplaceAPI';
import { NPValidationError } from '../forms/NPValidationError';

/**
 * Content of a <Popup>, to display an OTP window.
 * 
 * @param partyType Mandatory. Can be either "buyer" or "seller"
 * @param onClose Mandatory. Reacts to the user closing the window. 
 * @returns 
 */
export function OTPTxConfirmationPopup({ tid, onClose, onPropertyTransfered, partyType, profile, carrierInfo }) {

    const [phoneCountryCode, setPhoneCountryCode] = useState(profile?.phoneCountryCode)
    const [phone, setPhone] = useState(profile?.phone);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [otpSent, setOTPSent] = useState(false);
    const [insertedOTP, setInsertedOTP] = useState();
    const [error, setError] = useState(null)
    const [verifyingOTP, setVerifyingOTP] = useState(false)

    /**
     * Sends an OTP to the phone number
     */
    const sendOTP = async () => {

        setSendingOTP(true);

        if (profile != null) await new MarketplaceAPI().sendOTP(tid, phoneCountryCode, phone);
        else await new MarketplaceAPI().sendBuyerReceivedGoodsOTP(tid);

        setSendingOTP(false);
        setOTPSent(true);
    }

    /**
     * Mark the property as transferred
     */
    const postPropertyTransferWithOTP = async () => {

        setVerifyingOTP(true)

        let result
        if (profile != null) result = await new MarketplaceAPI().postPropertyTransferWithOTP(tid, insertedOTP, carrierInfo);
        else result = await new MarketplaceAPI().postSimplifiedBuyerReceivedGoods(tid, insertedOTP);

        setVerifyingOTP(false)

        if (result && result.done === true && onPropertyTransfered) onPropertyTransfered();

        if (result && result.code == 400) setError(`Si è verificato un errore: ${result.message}`)

        if (result && result.subcode == "otp-no-match") setError("L'OTP inserito non corrisponde a quello inviato.")

    }

    return (
        <NPCard>
            <div className="otp-confirmation-popup">
                <div className="card-header">
                    <div className="card-title">Conferma del completamento della Compravendita.</div>
                    <div>
                        <NPIconButton image={<CloseSVG />} onClick={onClose} />
                    </div>
                </div>
                <NPCardMessage icon="warn" color="complementary" backgroundColor="complementary" text={<>Stai per completare la transazione di compravendita. Ti invieremo un OTP al numero indicato qui sotto per conferma. <br />Una volta inserito l'OTP, la transazione sarà considerata conclusa e i fondi svincolati.</>} />

                <HSpacer size="large" />

                <div className='phone-container'>
                    {profile && <NPPhone label="Numero al quale ricevere l'OTP" countryCodeValue={profile.phoneCountryCode} phoneValue={profile.phone} onChangeCountryCode={setPhoneCountryCode} onChangePhoneNumber={setPhone} />}
                    <div className='button-container' style={{ display: 'flex' }}>
                        {!otpSent &&
                            <NPButton label="Invia OTP" onClick={sendOTP} disabled={sendingOTP} />
                        }
                    </div>
                </div>
                {!sendingOTP && otpSent &&
                    <div className={`otp-sent-text ${profile == null ? "large" : ""}`}>L'OTP è stato inviato. Se non lo ricevi entro 2 minuti, <span className='link' onClick={sendOTP}>clicca qui per inviare un nuovo OTP</span>.</div>
                }

                <HSpacer size="large" />

                {error && <NPValidationError errorMessage={error} />}

                {otpSent &&
                    <div className='phone-container'>
                        <div style={{ display: 'flex' }} className='otp-input-container'>
                            <NPTextInput label="Inserisci l'OTP ricevuto" transform="uppercase" onChange={(v) => { setInsertedOTP(v) }} />
                        </div>
                        <div className='button-container' >
                            <NPButton label="Conferma" onClick={postPropertyTransferWithOTP} disabled={verifyingOTP} />
                        </div>
                    </div>
                }


            </div>
        </NPCard>
    )
}