import './IdentityCardExample.css'
import ciPaperInside from '../../../images/docs/ci-cartacea-inside.png';
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow';

export function IdentityCardExamplePaperInside() {
    return (
        <div className='id-card-example'>
            <ResponsiveRow align="center" justify="center">
                <img src={ciPaperInside} />
            </ResponsiveRow>
        </div>
    )
}