import './CustomerDocumentScreen.css'
import { useHistory, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { HSpacer } from "../comp/layout/HSpacer";
import { Navigator } from "../comp/layout/Navigator";
import withProtection from "./hoc/WithProtection";
import withSingleColumnsLayout from "./hoc/WithSingleColumnLayout";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import MarketplaceAPI from "../api/MarketplaceAPI";
import { CustomerDocUploadCard } from './parts/docs/CustomerDocUploadCard';
import ProfileAPI from '../api/ProfileAPI';

import { ReactComponent as TickSVG } from '../icons/tick.svg'
import { NPNotificationCard } from '../comp/card/NPNotificationCard';
import { CustomerDocSummaryCard } from './parts/docs/CustomerDocSummaryCard';
import { NPLoadingBar } from '../comp/NPLoadingBar';
import Cookies from 'universal-cookie';
import { CustomerDocUploadVisura } from './parts/docs/CustomerDocUploadVisura';

const cookies = new Cookies()

function CustomerDocumentScreen() {

    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState()
    const [missingDoc, setMissingDoc] = useState()
    const [profile, setProfile] = useState()

    const [docsJustUploaded, setDocsJustUploaded] = useState(false)

    const history = useHistory()

    const loggedUser = cookies.get('user');

    /**
     * Retrieve my transactions (as a Customer)
     */
    const reload = async () => {

        setLoading(true);

        // Get the user profile
        const profile = await new ProfileAPI().getProfileData()

        setProfile(profile)

        // Get the user notifications
        const notificationsResponse = await new MarketplaceAPI().getMyNotifications();

        setLoading(false)

        if (notificationsResponse && !notificationsResponse.code) {
            setNotifications(notificationsResponse.notifications);

            for (let notif of notificationsResponse.notifications) {
                if (notif.code == 'missing-doc') setMissingDoc(true);
            }
        }

    }

    useEffect(() => { reload() }, [])

    if (loading) return (
        <div className="customer-doc-screen">
            <HSpacer />
            <Navigator backLabel="Torna alla dashboard" onBack={() => { history.push('/dashboard') }} />
            <HSpacer size="large" />
            <NPLoadingBar />
        </div>
    )

    return (
        <div className="customer-doc-screen">

            <HSpacer size="large" />
            <Navigator backLabel="Torna alla dashboard" onBack={() => { history.push('/dashboard') }} />
            <HSpacer size="large" />

            <NPNotificationCard icon={<TickSVG />} message="Il tuo documento è stato caricato correttamente." trigger={docsJustUploaded} />

            {missingDoc && !docsJustUploaded && loggedUser.userType != 'company' && <CustomerDocUploadCard onCompleted={() => { setDocsJustUploaded(true) }} />}
            {missingDoc && !docsJustUploaded && loggedUser.userType == 'company' && <CustomerDocUploadVisura onCompleted={() => { setDocsJustUploaded(true) }} />}

            {(!missingDoc || docsJustUploaded) && <CustomerDocSummaryCard profile={profile}/>}


        </div>
    )

}



export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(CustomerDocumentScreen)),
        "narrow"
    )
);
