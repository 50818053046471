import './WelcomeSection.css'
import { Column } from "../../../comp/layout/Column";
import { ResponsiveRow } from "../../../comp/layout/ResponsiveRow";

import { ReactComponent as AppImageSVG } from '../../../images/app-image.svg'
import { LandingSection } from '../../../comp/layout/LandingSection';

export function WelcomeRegistration() {

    return (
        <LandingSection background="#f4f4f4" className="welcome-section">
            <ResponsiveRow>
                <Column>
                    <div className="title"><b>Vuoi usufruire del servizio Pago Affare Trattore?</b></div>
                    <div className="text">
                        <p>Crea un profilo smart inserendo i dati richiesti qui sotto e lasciati guidare da Affare Trattore e Pleggit! </p>
                    </div>
                </Column>
                <div className="welcome-image-container small">
                    <AppImageSVG />
                </div>
            </ResponsiveRow>
        </LandingSection>
    )

}