import React from 'react';
import './WithSingleColumnLayout.css';
import './InAppLayout.css';
import NPBreadCrumb from '../../comp/NPBreadCrumb';
import Header from '../../comp/Header';
import Footer from '../../Footer';

/**
 * 
 * @param {Component} Title (OPT) the title component 
 * 
 * @param {*} Content (MAND) the content of the page
 * 
 * @param {*} style (OPT) a style string. 
 *  Can be
 *  - "default" - default style
 *  - "wide" - wide style, where the content is going to take a good part of the page
 *  - "narrow" - the (single) column will take approximately half the width and be centered in the page. For smaller screens, it will be more than half the screen (e.g. for mobile will be fullscreen).
 * 
 * @param {object} params A set of parameters:
 *  - breadcrumb        : (OPT, (true | false) default true) shows a breadcrumb. 
 * 
 * @returns 
 */
export default function withSingleColumnsLayout(Content, style, params) {

    return class extends React.Component {

        render() {

            let layoutStyle = style ? `style-${style}` : 'style-default';

            let layoutClass = 'in-app-screen ' + layoutStyle;

            let breadcrumb = (<NPBreadCrumb />)

            if (params) {
                if (params.breadcrumb == false) breadcrumb = null;
            }

            return (
                <div className={layoutClass}>
                    <Header />
                    <div className="page single">
                        {/* <NPBreadCrumb /> */}
                        <div className="content">
                            <Content />
                        </div>
                        <Footer hideMedia={true} hideSupport={true} />
                    </div>
                </div>
            )

        }
    }
}