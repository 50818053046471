import React from 'react';
import './TransactionHeaderCard.css';
import { withTranslation } from 'react-i18next';
import NPCard from '../../../comp/card/NPCard';
import NPLabeledValue from '../../../comp/NPLabeledValue';
import AffareTrattoreAPI from '../../../api/AffareTrattoreAPI';

/**
 * Displays the key data of the transaction
 */
function TransactionHeaderCard({ transaction }) {

    if (!transaction) return (<></>)

    const imageURL = new AffareTrattoreAPI().getAdImageProxiedUrl(transaction.adImageURL);

    const currency = transaction.currency ? transaction.currency : "EUR";

    return (
        <NPCard>
            <div className="tx-header-card">
                <div className='image'>
                    <img src={imageURL} />
                </div>
                <div className="tx-header-box">
                    <NPLabeledValue textAlign="center" size="large" label="Oggetto della compravendita" value={transaction.adTitle} />
                </div>
                {transaction.price &&
                    <div className="tx-header-box">
                        <NPLabeledValue textAlign="center" size="large" label="label.amount" type="currency" currency={currency} value={transaction.price} />
                    </div>
                }

            </div>
        </NPCard>
    )
}

export default withTranslation()(TransactionHeaderCard);