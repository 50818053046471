import Cookies from "universal-cookie";
import NPLabeledValue from "../../NPLabeledValue";
import NPCard from "../NPCard";
import { NPCardMessage } from "../NPCardMessage";
import './TransactionPaymentCard.css';
import { PaymentForm } from "../../../screens/parts/dashboard/form/PaymentForm";

const cookies = new Cookies()

export function TransactionPaymentCard({ transaction }) {

    const loggedUser = cookies.get("user")

    const amount = parseFloat(transaction.price) + parseFloat(transaction.fees ? transaction.fees : 0);

    if (loggedUser.userId == transaction.buyerUserId && !transaction.buyerPaidOn) return (
        <NPCard>
            <PaymentForm tx={transaction} />
        </NPCard>
    )

    return (
        <NPCard>
            <div className="agency-tx-payment-card row">
                <div className="status">
                    {!transaction.buyerPaidOn &&
                        <>
                            <div className="title">Il Compratore non ha ancora effettuato il pagamento.</div>
                            {/* The seller should not be able to see the following details. */}
                            {loggedUser.email != transaction.sellerEmail &&
                                <>
                                    <div className="info-text">In attesa di un bonifico che abbia il seguente importo e causale:</div>
                                    <div className="row">
                                        <NPLabeledValue label="Importo" value={amount} type="currency" />
                                        <NPLabeledValue label="Causale" value={transaction.paymentCode} />
                                    </div>
                                </>
                            }
                        </>
                    }
                    {transaction.buyerPaidOn != null && <NPCardMessage noBorder={true} icon="ok" date={transaction.buyerPaidOn} text={`Il Pagamento di ${amount.toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 })} è stato ricevuto.`} />}
                </div>
            </div>
        </NPCard>
    )
}