import NPAPI from './NPAPI';

export default class ProfileAPI {

  /**
   * Check user auth
   */
  getProfileCompletion() {

    return new NPAPI().fetch('profile', '/completion').then((response) => response.json());

  }

  getProfiles(filters) {

    let filter = '';
    if (filters && Object.keys(filters).length >= 0 && filters.constructor === Object) {
      filter += '?';

      if (filters.profileVerified != null) filter += 'profileVerified=' + filters.profileVerified + "&";
      if (filters.userComplete != null) filter += 'userComplete=' + filters.userComplete + "&";

    }

    return new NPAPI().fetch('profile', '/profiles' + filter).then((response) => response.json());
  }

  getProfileById(profileId) {

    return new NPAPI().fetch('profile', '/profiles/' + profileId).then((response) => response.json());
  }

  deleteProfile(id) {

    return new NPAPI().fetch('profile', `/admin/profiles/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json())

  }

  getProfileByUserId(userId) {

    return new NPAPI().fetch('profile', '/users/' + userId + '/profile').then((response) => response.json());
  }

  getOCRMandatoryFields() {

    return new NPAPI().fetch('profile', '/ocr/mandatory').then((response) => response.json());
  }

  getOCRData() {

    return new NPAPI().fetch('profile', '/profile/ocrData').then((response) => response.json());
  }

  getOCRDataByUserId(userId) {

    return new NPAPI().fetch('profile', '/users/' + userId + '/profile/ocrData').then((response) => response.json());
  }

  getOCRPartialMatchesByUserId(value) {

    return new NPAPI().fetch('profile', '/profile/ocrData/partial?value=' + value).then((response) => response.json());
  }

  postOCRCorrections(corrections) {

    return new NPAPI().fetch('profile', '/profile/ocrData/corrections', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(corrections)
    }).then((response) => response.json())

  }


  /**
   * Updates the profile with the provided data
   * 
   * @param {object} data the data to update the profile with. 
   * Fields have to follow this strict convention: 
   *  - cf                : codice fiscale
   *  - firstName         : first name
   *  - lastName          : last name
   *  - residenceCity     : city of residence
   *  - residenceAddress  : address of residence
   *  - birthDate         : date of birth, as a moment() object
   *  - birthCity         : city of birth
   *  - birthCountry      : country of birth
   *  - docType           : document type
   *  - docNum            : document number
   *  - docExpiryDate     : date of expiry of the doc, as a moment() object
   *  - docPhotoFrontLink : link of the photo front
   *  - docPhotoBackLink  : link of the back of the photo
   */
  updateProfile(data) {

    let profile = {};

    if (data.cf) profile.cf = data.cf;
    if (data.firstName) profile.firstName = data.firstName;
    if (data.middleNames) profile.middleNames = data.middleNames;
    if (data.lastName) profile.lastName = data.lastName;
    if (data.sex) profile.sex = data.sex;
    if (data.residenceAddress) profile.residenceAddress = data.residenceAddress;
    if (data.residenceCAP) profile.residenceCAP = data.residenceCAP;
    if (data.residenceCity) profile.residenceCity = data.residenceCity;
    if (data.residenceProvince) profile.residenceProvince = data.residenceProvince;
    if (data.residenceCountry) profile.residenceCountry = data.residenceCountry;
    
    if (data.birthDate) profile.birthDate = data.birthDate;
    if (data.birthCity) profile.birthCity = data.birthCity;
    if (data.birthCountry) profile.birthCountry = data.birthCountry;
    if (data.birthProvince) profile.birthProvince = data.birthProvince;
    
    if (data.docType) profile.docType = data.docType;
    if (data.docNum) profile.docNum = data.docNum;
    if (data.docExpiryDate) profile.docExpiryDate = data.docExpiryDate;
    if (data.docPhotoFrontLink) profile.docPictureFrontLink = data.docPhotoFrontLink;
    if (data.docPhotoBackLink) profile.docPictureBackLink = data.docPhotoBackLink;
    
    if (data.piva) profile.piva = data.piva;
    if (data.legalName) profile.legalName = data.legalName;
    if (data.regOfficeAddress) profile.regOfficeAddress = data.regOfficeAddress;
    if (data.regOfficeCAP) profile.regOfficeCAP = data.regOfficeCAP;
    if (data.regOfficeCity) profile.regOfficeCity = data.regOfficeCity;
    if (data.regOfficeProvince) profile.regOfficeProvince = data.regOfficeProvince;
    if (data.regOfficeCountry) profile.regOfficeCountry = data.regOfficeCountry;
    if (data.visuraCameraleLink) profile.visuraCameraleLink = data.visuraCameraleLink;
    
    if (data.acceptedPrivacy) profile.acceptedPrivacy = data.acceptedPrivacy;
    if (data.acceptedTerms) profile.acceptedTerms = data.acceptedTerms;
    if (data.acceptedSpecialTerms) profile.acceptedSpecialTerms = data.acceptedSpecialTerms;

    if (data.agencyId) profile.agencyId = data.agencyId;

    return new NPAPI().fetch('profile', '/profile', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(profile)
    }).then((response) => response.json())

  }

  /**
   * Retrieves the user profile
   */
  getProfileData() {

    return new NPAPI().fetch('profile', '/profile').then((response) => response.json());

  }

  async getCountries() {

    return new NPAPI().fetch('profile', '/countries').then((response) => response.json());

  }

  async getITProvinces() {

    return new NPAPI().fetch('profile', '/provinces/it').then((response) => response.json());

  }

  async getITComuni(provName) {

    return new NPAPI().fetch('profile', `/comuni?provincia=${provName}`).then((response) => response.json());
    
  }

  async verifyPhone(phoneCountryCode, phone, otp) {

    return new NPAPI().fetch('profile', '/phone/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phoneCountryCode: phoneCountryCode, 
        phone: phone, 
        otp: otp
      })
    })
  }

}
