import React, { Component, useState } from 'react';
import './DashboardScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import { WelcomeSection } from './parts/landing/WelcomeSection';
import { ATScreen } from '../comp/layout/ATScreen';
import { LandingCreateProfile } from './parts/landing/LandingCreateProfile';
import { LandingRegistered } from './parts/landing/LandingRegistered';

const cookies = new Cookies();

function MarketplaceLandingScreen() {

    const [registered, setRegistered] = useState(false)

    /**
     * Function called when the user has successfully registered
     */
    const onProfileRegistered = () => {
        
        setRegistered(true)
    }

    return (
        <ATScreen>

            <WelcomeSection />

            {!registered && <LandingCreateProfile onCompleted={onProfileRegistered} />}

            {registered && <LandingRegistered />}

        </ATScreen>
    )

}

export default withSingleColumnsLayout(withRouter(withTranslation()(MarketplaceLandingScreen)))