import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import './RegistrationScreen.css';
import { withRouter } from 'react-router';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import { ATScreen } from '../comp/layout/ATScreen';
import { LandingCreateProfile } from './parts/landing/LandingCreateProfile';
import { LandingRegistered } from './parts/landing/LandingRegistered';
import { WelcomeRegistration } from './parts/landing/WelcomeRegistration';

function RegistrationScreen() {

    const [registered, setRegistered] = useState(false)

    /**
     * Function called when the user has successfully registered
     */
    const onProfileRegistered = () => {
        
        setRegistered(true)
    }

    return (
        <ATScreen>

            <WelcomeRegistration />

            {!registered && <LandingCreateProfile onCompleted={onProfileRegistered} noAd={true} enableMarketplaceSeller={true} />}

            {registered && <LandingRegistered noAd={true} />}

        </ATScreen>
    )

}
export default withSingleColumnsLayout(withRouter(withTranslation()(RegistrationScreen)))  