import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './LoginScreen.css';
import './RegistrationScreen.css';
import { withRouter } from 'react-router';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import { ATScreen } from '../comp/layout/ATScreen';
import { LandingCreateProfile } from './parts/landing/LandingCreateProfile';
import { LandingRegistered } from './parts/landing/LandingRegistered';
import { WelcomeRegistration } from './parts/landing/WelcomeRegistration';
import NPCard from '../comp/card/NPCard';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AffareTrattoreAPI from '../api/AffareTrattoreAPI';
import TransactionHeaderCard from './parts/tx/TransactionHeaderCard';
import { NPLoadingBar } from '../comp/NPLoadingBar';
import { NPCardMessage } from '../comp/card/NPCardMessage';
import { HSpacer } from '../comp/layout/HSpacer';
import NPButton from '../comp/NPButton';
import Popup from 'reactjs-popup';
import { OTPTxConfirmationPopup } from '../comp/OTPTxConfirmationPopup';

function BuyerReceptionConfirmationScreen() {

    const [idAd, setIdAd] = useState()
    const [adTitle, setAdTitle] = useState()
    const [adImageURL, setAdImageURL] = useState()

    const [tid, setTid] = useState()

    const [showOTPConfirmation, setShowOTPConfirmation] = useState(false)
    const [propertyTransferred, setPropertyTransferred] = useState(false)

    const [loadingAd, setLoadingAd] = useState()

    const location = useLocation()

    /**
     * Load the Marketplace Ad
     */
    const loadAdData = async () => {

        const idAd = new URLSearchParams(location.search).get('id')
        const tid = new URLSearchParams(location.search).get('tid')

        if (!idAd) return;
        if (!tid) return;

        setIdAd(idAd)
        setTid(tid);

        setLoadingAd(true)

        const adDetails = await new AffareTrattoreAPI().getAdDetails(idAd);

        setLoadingAd(false)

        if (adDetails) {
            setAdTitle(adDetails.title)
            setAdImageURL(new AffareTrattoreAPI().getAdImageProxiedUrl(adDetails.image))
        }

    }

    /**
     * When the property transfer has been confirmed by the user
     */
    const onPropertyTransferred = () => {

        setShowOTPConfirmation(false);
        setPropertyTransferred(true)

    }

    useEffect(() => { loadAdData() }, [])

    if (loadingAd) return (<NPCard><NPLoadingBar /></NPCard>)

    return (
        <>
            <TransactionHeaderCard transaction={{ adTitle: adTitle, adImageURL: adImageURL }} />

            {!propertyTransferred &&
                <NPCard>
                    <NPCardMessage noBorder={true} icon="action" text="Hai ricevuvto l'oggetto della compravendita?" instructions="Completa la transazione confermando la ricezione del bene." />
                    <HSpacer />

                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <NPButton label="Ho ricevuto il bene" onClick={() => { setShowOTPConfirmation(true) }} />
                    </div>
                </NPCard>
            }

            {propertyTransferred &&
                <NPCard>
                    <NPCardMessage noBorder={true} icon="ok" text="Hai confermato di aver ricevuto il bene in oggeto." instructions="La transazione è conclusa, puoi chiudere questa finestra." />
                </NPCard>
            }

            <Popup open={showOTPConfirmation} onClose={() => { setShowOTPConfirmation(false) }}>
                <OTPTxConfirmationPopup tid={tid} onClose={() => { setShowOTPConfirmation(false) }} onPropertyTransfered={onPropertyTransferred} />
            </Popup>
        </>
    )

}
export default withSingleColumnsLayout(withRouter(withTranslation()(BuyerReceptionConfirmationScreen)), "narrow")  