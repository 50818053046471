import './IdentityCardSelector.css';
import { useState } from "react";
import NPSelect from "../../../forms/NPSelect";
import { ResponsiveRow } from "../../../comp/layout/ResponsiveRow";
import Popup from 'reactjs-popup';
import NPCard from '../../../comp/card/NPCard';
import NPIconButton from '../../../comp/NPIconButton';

import ciPaperOutside from '../../../images/docs/ci-cartacea-outside.png';
import ciPaperInside from '../../../images/docs/ci-cartacea-inside.png';
import ciElFront from '../../../images/docs/ci-el-front.png';
import ciElBack from '../../../images/docs/ci-el-back.png';

import { ReactComponent as CloseSVG } from '../../../icons/close.svg'
import { HSpacer } from '../../../comp/layout/HSpacer';

const CI_TYPES = [
    { value: "electronic", label: "Carta di Identità Elettronica" },
    { value: "paper", label: "Carta di Identità Cartacea" },
]

export function IdentityCardSelector({ onChange }) {

    const [showExamples, setShowExamples] = useState(false)

    return (
        <div className="identity-card-selector">

            <NPSelect label="Tipo di Carta di Identità" options={CI_TYPES} onChange={onChange} onHelp={() => { setShowExamples(true) }} />

            <Popup open={showExamples} onClose={() => { setShowExamples(false) }}>
                <NPCard>
                    <div className="card-header">
                        <div className="card-title">Tipi di Carta di Identità</div>
                        <div>
                            <NPIconButton image={<CloseSVG />} onClick={() => { setShowExamples(false) }} />
                        </div>
                    </div>
                    <div className="id-card-selector-example">
                        <div className="example-title">
                            <div className='num'><b>1</b></div>
                            Esempio di {CI_TYPES[0].label}
                        </div>
                        <ResponsiveRow align="center" justify="center">
                            <img src={ciPaperOutside} />
                            <img src={ciPaperInside} />
                        </ResponsiveRow>
                    </div>
                    <HSpacer />
                    <div className="id-card-selector-example">
                        <div className="example-title">
                            <div className='num'><b>2</b></div>
                            Esempio di {CI_TYPES[1].label}
                        </div>
                        <ResponsiveRow align="center" justify="center">
                            <img src={ciElFront} />
                            <img src={ciElBack} />
                        </ResponsiveRow>
                    </div>
                </NPCard>
            </Popup>

        </div>
    )
}