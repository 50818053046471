import { useState } from "react";
import NPCard from "../NPCard";
import { NPCardMessage } from "../NPCardMessage";
import NPTextInput from "../../../forms/NPTextInput";
import IBAN from "iban";
import { NPCheckBox } from "../../NPCheckBox";
import { HSpacer } from "../../layout/HSpacer";
import NPButton from "../../NPButton";
import { isBuyer } from "../../../util/UserUtil";
import MarketplaceAPI from "../../../api/MarketplaceAPI";
import { NPLoadingBar } from "../../NPLoadingBar";
import NPLabeledValue from "../../NPLabeledValue";

export function IbanCard({ transaction, onIBANSaved }) {

    const [ibanConfirmed, setIbanConfirmed] = useState(false)
    const [iban, setIban] = useState()
    const [savingIBAN, setSavingIBAN] = useState()

    // Cases in which the card should not be shown
    if (transaction.sellerConfirmedPTOn == null || transaction.buyerConfirmedPTOn == null) return <></>;
    if (isBuyer(transaction)) return <></>
    if (transaction.sellerPaidOn != null) return <></>

    /**
     * Verifies the IBAN
     * 
     * @param {string} value the iban to verify
    */
    const validateIBAN = async (value) => {

        if (!IBAN.isValid(value)) throw { message: "L'IBAN inserito non è valido." }

    }

    /**
     * Confirms the iban
    */
    const onConfirmIbanClick = () => {

        if (!ibanConfirmed && !IBAN.isValid(iban)) return;

        setIbanConfirmed(!ibanConfirmed)
    }

    /**
     * Posts the IBAN
     */
    const postIBAN = async () => {

        if (IBAN.isValid(iban) && ibanConfirmed) {

            setSavingIBAN(true)

            await new MarketplaceAPI().postIBAN(transaction.id, iban);

            if (onIBANSaved) onIBANSaved();

            setSavingIBAN(false)
        }

    }

    // If the user has inserted the IBAN...
    if (transaction.iban != null) return (
        <NPCard>
            <NPCardMessage icon="money" noBorder={true} date={transaction.ibanProvidedOn} text="Stiamo provvedendo ad effettuare il bonifico all'IBAN indicato." instructions={<>Dovresti ricevere i fondi (meno l'importo delle commissioni) entro <b>5 giorni lavorativi</b>.</>} />
            <HSpacer />
            <NPLabeledValue label="IBAN indicato per il bonifico" value={transaction.iban} />
        </NPCard>
    )

    return (
        <NPCard>
            <NPCardMessage text="Inserisci l'IBAN a cui desideri ricevere l'importo della compravendita (da cui vengono detratte le commissioni)." />
            <HSpacer />

            {savingIBAN && <NPLoadingBar label="Salvataggio IBAN in corso.." />}

            {!savingIBAN &&
                <>
                    <div className='input-container'>
                        <NPTextInput label="Inserisci il tuo IBAN" validator={validateIBAN} onChange={(v) => { setIban(v) }} />
                    </div>
                    <div className='input-container'>
                        <NPCheckBox
                            label="Confermo che l'IBAN inserito è corretto e sono consapevole che non avrò modo di cambiarlo."
                            ticked={IBAN.isValid(iban) && ibanConfirmed}
                            onClick={onConfirmIbanClick}
                        />
                    </div>

                    <HSpacer />
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}></div>
                        <NPButton label="Confermo l'IBAN" onClick={postIBAN} disabled={iban == null || ibanConfirmed == false} />
                    </div>
                </>
            }
        </NPCard>
    )

}