import './IdentityCardExample.css'
import ciPaperOutside from '../../../images/docs/ci-cartacea-outside.png';
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow';

export function IdentityCardExamplePaper() {
    return (
        <div className='id-card-example'>
            <ResponsiveRow align="center" justify="center">
                <img src={ciPaperOutside} />
            </ResponsiveRow>
        </div>
    )
}