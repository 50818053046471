import './layout.css'
import NPButton from "../NPButton";
import { ReactComponent as ArrowSVG } from '../../icons/arrow-left.svg';

export function Navigator({ backLabel, onBack }) {

    return (
        <div className="navigator">
            <NPButton label={backLabel} imageLeft={<ArrowSVG />} onClick={onBack} />
            <div style={{ flex: 1 }}></div>
        </div>
    )

}