import './LoginBox.css'
import { HSpacer } from '../../../comp/layout/HSpacer'
import NPButton from '../../../comp/NPButton'
import { NPLink } from '../../../comp/NPLink'
import { NPLoadingBar } from '../../../comp/NPLoadingBar'
import NPTextInput from '../../../forms/NPTextInput'
import { ReactComponent as PleggitTitle } from '../../../images/logo/pleggit-title.svg';
import { ReactComponent as PleggitLogo } from '../../../images/logo/logo.svg';
import { NPValidationError } from '../../../forms/NPValidationError'
import { useState } from 'react'
import AuthAPI from '../../../api/AuthAPI'
import { storeUser } from '../../../util/UserUtil'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function LoginBox() {

    const [logInOngoing, setLogInOngoing] = useState()
    const [error, setError] = useState()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()

    const history = useHistory()

    /**
     * Performs the login
     */
    const onLoginClick = async () => {

        if (!username || !password) {
            setError("Per favore, inserisci username e password")
            return;
        }

        setError(null)
        setLogInOngoing(true)

        const result = await new AuthAPI().signIn(username, password)

        setLogInOngoing(false)

        if (!result) return;

        if (result.userExists === false) {
            setError("L'utente non è stato trovato")
            return;
        }

        if (result.loginSucceeded === false) {
            setError("Username o password errate")
            return;
        }

        if (result.accessToken) getInTheApp(result, "pleggit")

    }

    /**
     * Alright, you have signed in and are actually authorized to enter, so let's do that! 
     */
    const getInTheApp = (user, authProvider) => {

        console.log("[login] - User is authorized to access!");

        storeUser(user, authProvider);

        // Initialize Push Listener
        // pushListener.onLogin(user.userId);

        history.push('/dashboard');

    }

    return (
        <div className="login-container">

            <div className="logo-container">
                <div className="pleggit-logo" ><PleggitLogo /></div>
                <div className="pleggit-title" ><PleggitTitle /></div>
            </div>

            {logInOngoing && <NPLoadingBar label="Login in corso.." />}

            {!logInOngoing &&
                <>
                    <div className="input-container">
                        <NPTextInput label="Indirizzo email" onChange={(value) => { setUsername(value) }} />
                        <NPTextInput label="Password" type="password" onChange={(value) => { setPassword(value) }} onPressEnter={onLoginClick} />
                    </div>

                    {error && <NPValidationError errorMessage={error} />}

                    <div className="button-container">
                        <NPButton label="Login" onClick={onLoginClick} />
                    </div>

                    <HSpacer size="large" />

                    <div style={{ textAlign: 'center' }}>
                        <NPLink href="/register" text="Oppure Registrati, se non sei ancora registrato." />
                    </div>
                </>
            }
        </div>
    )

}