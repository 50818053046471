import './NPPhone.css'
import NPTextInput from './NPTextInput'

export function NPPhone({ label, onChangeCountryCode, onChangePhoneNumber, countryCodeValue, phoneValue }) {

    return (
        <div className='phone-input np-text-input'>
            <div className="np-input-label">
                {label}
            </div>
            <div className='field'>
                <div className='country-code-container'>
                    <NPTextInput placeholder="+39" mandatory={true} prefilled={countryCodeValue} onChange={onChangeCountryCode} />
                </div>
                <div className='phone-container'>
                    <NPTextInput mandatory={true} type="number" prefilled={phoneValue} onChange={onChangePhoneNumber} />
                </div>
            </div>
        </div>
    )
}