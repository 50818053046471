import { useEffect, useState } from 'react'
import './CustomerDocUploadCard.css'
import FileAPI from '../../../api/FileAPI'
import ProfileAPI from '../../../api/ProfileAPI'
import NPCard from '../../../comp/card/NPCard'
import { HSpacer } from '../../../comp/layout/HSpacer'
import NPSelect from '../../../forms/NPSelect'
import { IdentityCardExamplePaper } from './IdentityCardExamplePaper'
import { IdentityCardExamplePaperInside } from './IdentityCardExamplePaperInside'
import { IdentityCardExampleElBack, IdentityCardExampleElFront } from './IdentityCardExampleEl'
import { IdentityCardSelector } from './IdentityCardSelector'
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow'
import { PassportExample } from './PassportExample'
import NPPhotoUploader from '../../../forms/NPDocUploader'

const DOCS = [
    { value: "ci", label: "Carta di Identità" },
    { value: "passport", label: "Passaporto" }
]

/**
 * 
 * @param {function} onCompleted callback to use when the upload has been completed
 * @returns 
 */
export function CustomerDocUploadCard({ onCompleted }) {

    const [docType, setDocType] = useState()
    const [ciType, setCiType] = useState()

    const [loadingFront, setLoadingFront] = useState(false)
    const [loadingBack, setLoadingBack] = useState(false)
    const [frontImage, setFrontImage] = useState()
    const [backImage, setBackImage] = useState()

    const [uploadedFront, setUploadedFront] = useState(false)
    const [uploadedBack, setUploadedBack] = useState(false)

    /**
     * Function to upload the passport
     * This function uploads the only image to both "front" and "back" on File API
     */
    const onPassportUpload = async (file) => {

        setLoadingFront(true)

        await new FileAPI().uploadIDPhoto('front', file);
        new FileAPI().uploadIDPhoto('back', file);

        // Pass back the photo link
        await new ProfileAPI().updateProfile({
            docType: docType,
            docPhotoFrontLink: "front",
            docPhotoBackLink: "back"
        })

        // Load the photo
        const imgSrc = await new FileAPI().downloadIDPhotoStream('front');

        // Update the state with the picture
        setFrontImage(imgSrc)
        setLoadingFront(false)
        setUploadedFront(true)
        setUploadedBack(true);

    }

    /**
     * Function to upload the file
     * 
     * @param {file} file the file selected for upload
     */
    const onPhotoFrontUpload = async (file) => {

        setLoadingFront(true)

        await new FileAPI().uploadIDPhoto('front', file);

        // Pass back the photo link
        await new ProfileAPI().updateProfile({
            docType: docType,
            docPhotoFrontLink: "front",
        })

        // Load the photo
        const imgSrc = await new FileAPI().downloadIDPhotoStream('front');

        // Update the state with the picture
        setFrontImage(imgSrc)
        setLoadingFront(false)
        setUploadedFront(true)

    }

    /**
     * Function to upload the file
     * 
     * @param {file} file the file selected for upload
     */
    const onPhotoBackUpload = async (file) => {

        setLoadingBack(true)

        await new FileAPI().uploadIDPhoto('back', file);

        // Pass back the photo link
        await new ProfileAPI().updateProfile({
            docType: docType,
            docPhotoBackLink: "back"
        })

        // Load the photo
        const imgSrc = await new FileAPI().downloadIDPhotoStream('back');

        // Update the state with the picture
        setBackImage(imgSrc)
        setLoadingBack(false)
        setUploadedBack(true);

    }

    useEffect(() => {
        
        if (uploadedFront && uploadedBack && onCompleted) onCompleted();

    }, [uploadedFront, uploadedBack])

    return (

        <NPCard>
            <div className='customer-doc-upload-card'>

                <div className="card-title">Caricamento documento</div>
                <div className="instructions">
                    Carica un documento di identità in corso di validità.<br />
                </div>

                <HSpacer />
                <NPSelect label="Tipo di documento" options={DOCS} onChange={(v) => { setDocType(v) }} />

                {docType == 'ci' && <IdentityCardSelector onChange={(v) => { setCiType(v) }} />}

                {docType == 'ci' && ciType == 'paper' &&
                    <>
                        <div className='upoad-box'>
                            <div className='label'>Carica una foto del <b>fronte e retro</b> della Carta di Identità, come mostrato nell'esempio.</div>
                            <ResponsiveRow>
                                <div style={{ marginRight: 12 }}>
                                    <NPPhotoUploader uploading={loadingFront} onChange={onPhotoFrontUpload} uploadedDoc={frontImage} />
                                </div>
                                <IdentityCardExamplePaper />
                            </ResponsiveRow>
                        </div>
                        <HSpacer />
                        <div className='upoad-box'>
                            <div className='label'>Carica una foto <b>dell'interno</b> della Carta di Identità, come mostrato nell'esempio.</div>
                            <ResponsiveRow>
                                <div style={{ marginRight: 12 }}>
                                    <NPPhotoUploader uploading={loadingBack} onChange={onPhotoBackUpload} uploadedDoc={backImage} />
                                </div>
                                <IdentityCardExamplePaperInside />
                            </ResponsiveRow>
                        </div>
                    </>
                }

                {docType == 'ci' && ciType == 'electronic' &&
                    <>
                        <div className='upoad-box'>
                            <div className='label'>Carica una foto del <b>fronte</b> della Carta di Identità elettronica, come mostrato nell'esempio.</div>
                            <ResponsiveRow>
                                <div style={{ marginRight: 12 }}>
                                    <NPPhotoUploader uploading={loadingFront} onChange={onPhotoFrontUpload} uploadedDoc={frontImage} />
                                </div>
                                <IdentityCardExampleElFront />
                            </ResponsiveRow>
                        </div>
                        <HSpacer />
                        <div className='upoad-box'>
                            <div className='label'>Carica una foto del <b>retro</b> della Carta di Identità elettronica, come mostrato nell'esempio.</div>
                            <ResponsiveRow>
                                <div style={{ marginRight: 12 }}>
                                    <NPPhotoUploader uploading={loadingBack} onChange={onPhotoBackUpload} uploadedDoc={backImage} />
                                </div>
                                <IdentityCardExampleElBack />
                            </ResponsiveRow>
                        </div>
                    </>
                }

                {docType == 'passport' &&
                    <>
                        <div className='upload-box'>
                            <div className='label'>Carica una foto del tuo <b>Passaporto</b>, come mostrato nell'esempio.</div>
                            <div className='details'><i>E' sufficiente caricare la foto della pagina in cui sono riportati i dati anagrafici, come mostrato nell'esempio qui sotto.</i></div>
                            <ResponsiveRow>
                                <div style={{ marginRight: 12 }}>
                                    <NPPhotoUploader uploading={loadingFront} onChange={onPassportUpload} uploadedDoc={frontImage} />
                                </div>
                                <PassportExample />
                            </ResponsiveRow>
                        </div>
                    </>
                }
            </div>
        </NPCard>
    )

}
