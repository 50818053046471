import React from 'react';
import { withTranslation } from 'react-i18next';
import './NPSelect.css';
import { ReactComponent as WarningSVG } from '../icons/exclamation-mark.svg';
import { ReactComponent as TickSVG } from '../icons/tick.svg';
import { ReactComponent as QuestionSVG } from '../icons/question-mark.svg';
import NPPopup from '../comp/popup/NPPopup';
import Select from 'react-select'
import NPIconButton from '../comp/NPIconButton';

const selectStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: '#e1e1e1',
        margin: "6px 0"
    })
}


/**
 * Select
 * 
 * Parameters: 
 * 
 *  - label                 :   (MAND) the label for the text input
 *  - placeholder           :   (OPT) the placeholder text
 *  - mandatory             :   (OPT, default false) Set to true if this field is mandatory
 *  - prefilled             :   (OPT, default none) Prefill the field with a value
 *  - validator             :   (OPT, default none) A custom validator function to call
 *                              The function must return a Promise where failure() will be called if there are validation errors. 
 *                              A validation error must be an object {message: ""}
 *  - transform             :   (OPT) specifies transformations to apply to the text
 *                              Admitted values: "capitalize"
 * 
 * Listeners
 * 
 *  - onChange              :   (OPT) listens to the change of the value 
 *  - onHelp                :   (OPT) listens to a click on the help button. Passing this callback will show a help button on the side of the select.
 */
class NPSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            validationErrors: [],
            validationPopupOpen: false,
            validationPopupPosition: { top: 0, left: 0 },
        };

        this.validate = this.validate.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    componentDidMount() {
        // Define the position of the validation popup
        let element = this.el;

        // Prefilled
        let defaultValue = 0;
        if (this.props.prefilled) {
            for (var i = 0; i < this.props.options.length; i++) {
                if (this.props.options[i].value == this.props.prefilled) {
                    defaultValue = i;
                    break;
                }
            }
        }

        this.changeValue(this.props.options[defaultValue]);

        this.setState({
            // validationPopupPosition: {
            //     top: element.clientHeight / 2,
            //     left: element.clientWidth + 12
            // }, 
            defaultValue: defaultValue
        })

    }

    validate(event) {

        const { t } = this.props;

        let value = event.target.value;

        let validationErrors = [];
        let validationPromises = [];

        // Mandatory validation
        validationPromises.push(new Promise((success, failure) => {

            if (this.props.mandatory) {
                if (!value) validationErrors.push({ message: t('forms.validation.error.mandatory') });
                else if (value.trim() == '') validationErrors.push({ message: t('forms.validation.error.mandatory') });
            }

            success();
        }));

        // If a validator has been passed use it
        if (this.props.validator) validationPromises.push(new Promise((success, failure) => {

            this.props.validator(value).then(() => { success(); }, (validationError) => {

                validationErrors.push(validationError);

                success();

            });
        }));

        Promise.all(validationPromises).then(() => {

            // Show validation errors, if any
            if (validationErrors.length > 0) {
                this.setState({
                    validationErrors: validationErrors
                })
            }
            else this.setState({ validationErrors: [] });
        })

    }

    /**
     * Changes the value
     * @param {any} val the new value
     */
    changeValue(val) {

        if (this.props.onChange) this.props.onChange(val.value);
    }

    render() {

        const { t } = this.props;

        // Wait to render til the prefilled data is there
        if (this.state.defaultValue == null) return (<div></div>);

        // Classes 
        let widgetClass = "np-select";
        if (this.props.style) widgetClass += ' ' + this.props.style;

        let textInputClass = 'outline-primary';
        if (this.state.validationErrors && this.state.validationErrors.length > 0) textInputClass += ' border-complementary';
        else textInputClass += ' border-ok';

        // Feedback icon
        let icon;
        if (this.state.validationErrors && this.state.validationErrors.length > 0) icon = (
            <div className="feedback-icon complementary">
                <WarningSVG />
            </div>
        )
        else if (this.state.validationErrors && this.state.validationErrors.length == 0) icon = (
            <div className="feedback-icon primary">
                <TickSVG />
            </div>
        )

        // Input Container class
        let inputContainerClass = "text-input-container";
        if (this.props.transform) inputContainerClass += ' ' + this.props.transform;

        // Popup
        let popup;
        if (this.state.validationErrors && this.state.validationErrors.length > 0) popup = (
            <NPPopup
                position={this.state.validationPopupPosition}
                verticalPlacement="centered"
                style="validation"
                wrapText={false}
            >

                {this.state.validationErrors[0].message}
            </NPPopup>

        )

        return (
            <div className={widgetClass}>
                <div className="np-input-label">
                    {this.props.label}
                </div>
                <div className='row-wrapper'>
                    <div className={inputContainerClass} ref={(el) => { this.el = el }} onMouseOver={() => { this.setState({ validationPopupOpen: true }) }} onMouseOut={() => { this.setState({ validationPopupOpen: false }) }}>
                        <Select
                            options={this.props.options}
                            styles={selectStyles}
                            onChange={this.changeValue}
                            defaultValue={this.props.options[this.state.defaultValue]}
                        />
                        {icon}
                        {this.state.validationErrors && this.state.validationPopupOpen && popup}
                    </div>
                    {this.props.onHelp &&
                        <div className='help-icon'>
                            <NPIconButton image={<QuestionSVG />} size="small" onClick={this.props.onHelp} />
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default withTranslation()(NPSelect);