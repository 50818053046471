import React from 'react';
import './NPDocUploader.css';
import { ReactComponent as UploadSVG } from '../icons/upload.svg';
import { ReactComponent as TickSVG } from '../icons/uploaded.svg';
import { ReactComponent as LoaderSVG } from '../icons/loader-ball-triangle.svg';

/**
 * Document uploader component
 * 
 * Attention! This is a controlled component. 
 * It won't actually upload anything, but will return the file that has been chosen by the user. 
 * 
 * Parameters; 
 * 
 *  - uploadedDoc               :   (OPT) When null, this component will allow the user to upload a document
 *                                  When a loaded doc is provided, it will display it and allow the user to cancel and re-upload another picture.
 *  - uploadLabel               :   (MAND) The label to display under the upload icon in the button
 *  - label                     :   (OPT) The label to put before the button
 * 
 * Callback: 
 * 
 *  - onChange                  :   (OPT) callback called with the chosen file to be uploaded by the controller of this component
 * 
 */
export default class NPPhotoUploader extends React.Component {

    constructor(props) {
        super(props);

        this.id = 'np-photo-uploader-' + Math.random() * 100;

        this.onChange = this.onChange.bind(this);
        this.onDocLoaded = this.onDocLoaded.bind(this);
    }

    onChange(event) {

        let file = event.target.files[0];

        // Remove the file from the file input, so that if I load the same file again it all works fine
        event.target.value = '';

        // Pass the file back 
        if (this.props.onChange) this.props.onChange(file);

    }

    /**
     * When the picture has loaded
     * @param {event} event the event
     */
     onDocLoaded(event) {

        let pic = event.target;
        let picContainer = pic.parentNode;

        let width = pic.offsetWidth;
        let height = pic.offsetHeight;

        let containerWidth = picContainer.offsetWidth;
        let containerHeight = picContainer.offsetHeight;

        let ratio = width / height;

        if (width > height) {
            let newWidth = containerWidth * 1.1;
            pic.width = newWidth;
            pic.height = newWidth / ratio;
            pic.style.top = '-' + ((pic.height - containerHeight) / 2) + 'px';
            pic.style.left = '-' + ((pic.width - containerWidth) / 2) + 'px';
        }

    }

    render() {

        let clazz = 'np-doc-uploader';
        if (this.props.uploadedDoc) clazz += ' border-primary fill-primary shadow-on-mouseover focused-easeInOut-motion';
        else clazz += ' border-accent fill-accent shadow-on-mouseover focused-easeInOut-motion';

        let upload;
        if (!this.props.uploadedDoc && !this.props.uploading) upload = (
            <div className="upload-illustration dark-background">
                <div style={{ display: 'flex', flex: 1 }}></div>
                <div className="image-container">
                    <UploadSVG />
                </div>
                <div className="bold">{this.props.uploadLabel}</div>
                <div style={{ display: 'flex', flex: 1 }}></div>
            </div>
        )

        let confirmed;
        if (this.props.uploadedDoc && !this.props.uploading) confirmed = (
            <div className="upload-illustration dark-background done">
                <div style={{ display: 'flex', flex: 1 }}></div>
                <div className="image-container">
                    <TickSVG />
                </div>
                <div className="bold">{this.props.uploadedLabel}</div>
                <div style={{ display: 'flex', flex: 1 }}></div>
            </div>
        )

        let loading;
        if (this.props.uploading) loading = (
            <div className="upload-illustration dark-background">
                <div style={{ display: 'flex', flex: 1 }}></div>
                <div className="image-container" style={{transform: "scale(0.8)"}}>
                    <LoaderSVG />
                </div>
                <div className="bold">{this.props.uploadingLabel}</div>
                <div style={{ display: 'flex', flex: 1 }}></div>
            </div>
        )

        let img;
        if (this.props.uploadedDoc) img = (
            <div className='photo-container'>
                <img src={this.props.uploadedDoc} />
            </div>
        )

        return (
            <div className="np-doc-uploader-container">
                <div className="label">{this.props.label}</div>
                <div className={clazz} onClick={() => { if (!this.props.uploadedPicture) this.label.click() }}>

                    {upload}
                    {confirmed}
                    {loading}
                    {!this.props.uploading && img}

                    <div className="html-input-container">
                        <input type="file" hidden onChange={this.onChange} id={this.id} ref={(el) => { this.fileinput = el; }} />
                        <label ref={(el) => { this.label = el }} className="label" htmlFor={this.id} >
                            {this.props.label}
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}