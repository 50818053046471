import React, { Component } from 'react';
import './DashboardScreen.css';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import withProtection from './hoc/WithProtection';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import CustomerDashboard from './parts/dashboard/CustomerDashboard';

const cookies = new Cookies();

function Content() {

    return <CustomerDashboard />

}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(Content)),
        "narrow"
    )
);
