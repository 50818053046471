import './DashboardElements.css'

/**
 * Supports
 * 
 * - type: can be null, "currency"
 * 
 * @returns 
 */
export function LabeledValue({ label, value, type, currency }) {

    let formattedValue = value;
    // Formatting currencies
    if (type == 'currency') formattedValue = value ? value.toLocaleString('it-IT', { style: 'currency', currency: currency ? currency : "EUR", minimumFractionDigits: 2 }) : parseFloat(0).toLocaleString('it-IT', { style: 'currency', currency: currency ? currency : "EUR", minimumFractionDigits: 2 })

    return (

        <div className="labeled-value">
            <div className="label">{label}</div>
            <div className="value">{formattedValue}</div>
        </div>
    )
}

export function Tag({ content }) {
    return (
        <div className={`tag ${content}`}>{content}</div>
    )
}
