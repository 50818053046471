import { useEffect, useState } from "react";
import { NPCardMessage } from "../../../../comp/card/NPCardMessage";
import CompanyAPI from "../../../../api/CompanyAPI";
import NPLabeledValue from "../../../../comp/NPLabeledValue";
import { NPLoadingBar } from "../../../../comp/NPLoadingBar";
import './PaymentForm.css'
import { NPValidationError } from "../../../../forms/NPValidationError";

export function PaymentForm({ tx }) {

    const [companyInfo, setCompanyInfo] = useState()
    const [loading, setLoading] = useState(false)

    const totalAmount = parseFloat(tx.price) + parseFloat(tx.fees ? tx.fees : 0);

    /**
     * Load the company info
     */
    const loadCompanyInfo = async () => {

        const timer = setTimeout(() => { setLoading(true) }, 400)

        const apiResponse = await new CompanyAPI().getCompanyInfo()

        clearTimeout(timer)
        setLoading(false)

        setCompanyInfo(apiResponse);
    }

    useEffect(() => { loadCompanyInfo() }, [])

    if (!companyInfo) return <></>

    return (
        <div className="payment-form">
            {!loading &&
                <>
                    <NPValidationError errorMessage={<>Non abbiamo ancora ricevuto il bonifico per questa transazione.<br/>Se non l'hai ancora effettuato, queste sono le istruzioni per effettuare il bonifico:</>} />
                    <div className="instructions">
                        <div className="text">Il bonifico deve essere effettuato a: </div>
                        <div className="row">
                            <NPLabeledValue label="Intestatario" value={companyInfo.legalName} />
                            <NPLabeledValue label="IBAN" value={companyInfo.iban} copy={true} />
                        </div>
                        <div className="text">L'importo da bonificare (inclusivo di IVA e commissioni di <b>{tx.fees ? tx.fees.toLocaleString('it-IT', { style: 'currency', currency: "EUR", minimumFractionDigits: 2 }) : 0}</b>) e la causale sono:</div>
                        <div className="row">
                            <NPLabeledValue label="Importo da bonificare" value={totalAmount} type="currency" />
                            <NPLabeledValue label="Causale" value={tx.paymentCode} copy={true} />
                        </div>
                    </div>
                    <div className="disclaimer">
                        <i>Se hai già effettuato il bonifico, non considerare questo messaggio: appena ricevuto il bonifico, non comparirà più nella tua home.</i>
                    </div>
                </>
            }
            {loading && <NPLoadingBar />}
        </div>
    )

}