import './TransactionStateIcon.css'
import {ReactComponent as CarSVG} from '../icons/car.svg'
import {ReactComponent as HandsSVG} from '../icons/handshake.svg'
import {ReactComponent as PropertyTransferSVG} from '../icons/car-key.svg'
import {ReactComponent as WarnSVG} from '../icons/warning.svg';

export function TransactionStateIcon({ transaction }) {

    let transactionState = "waitingForBuyerPayment"
    if (transaction.buyerPaidOn != null) transactionState = "readyForPT"
    if (transaction.buyerConfirmedPTOn != null && transaction.sellerConfirmedPTOn != null) transactionState = "ptDone"
    if (transaction.sellerPaidOn != null) transactionState = "closed"

    return (
        <div className="tx-state-icon">
            {transactionState == 'readyForPT' && <CarSVG/>}
            {transactionState == 'ptDone' && <PropertyTransferSVG/>}
            {transactionState == 'closed' && <HandsSVG/>}
            {transactionState == 'waitingForBuyerPayment' && <div className='tx-state-icon' style={{color: 'var(--color-complementary)'}}><WarnSVG/></div>}
        </div>
    )

}