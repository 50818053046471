import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { Component } from 'react';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import LoginScreen from './screens/LoginScreen';
import DashboardScreen from './screens/DashboardScreen';
import RegistrationScreen from './screens/RegistrationScreen';
import UserActivatedScreen from './screens/UserActivatedScreen';
import TransactionDetailScreen from './screens/TransactionDetailScreen';
import MarketplaceLandingScreen from './screens/MarketplaceLandingScreen';
import CustomerDocumentScreen from './screens/CustomerDocumentScreen';
import BuyerReceptionConfirmationScreen from './screens/BuyerReceptionConfirmationScreen';

const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}'.json,
    },
    defaultNS: 'translation',
    ns: ['translation'],
    lng: "it",
    fallbackLng: 'en',
  }, (err, t) => {
    if (err) return console.error(err)
  });

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      gapiLoaded: false
    }


  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="App font-normal">
        <Router>
          <Switch>
            <Route exact path="/marketplace">
              <MarketplaceLandingScreen />
            </Route>
            <Route exact path="/">
              <LoginScreen />
            </Route>
            <Route exact path="/">
              <LoginScreen />
            </Route>
            <Route exact path="/login">
              <LoginScreen />
            </Route>
            <Route exact path="/dashboard">
              <DashboardScreen />
            </Route>
            <Route exact path="/transactions">
              <DashboardScreen />
            </Route>
            <Route exact path="/register">
              <RegistrationScreen />
            </Route>
            <Route exact path="/activate">
              <UserActivatedScreen />
            </Route>
            <Route exact path="/transaction">
              <TransactionDetailScreen />
            </Route>
            <Route exact path="/docs">
              <CustomerDocumentScreen />
            </Route>
            <Route exact path="/buyer/confirmation">
              <BuyerReceptionConfirmationScreen />
            </Route>
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;