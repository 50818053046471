import './CustomerDocSummaryCard.css';
import NPCard from "../../../comp/card/NPCard";
import { ReactComponent as InfoSVG } from '../../../icons/info.svg';
import { HSpacer } from '../../../comp/layout/HSpacer';
import NPLabeledValue from '../../../comp/NPLabeledValue';
import { useEffect, useState } from 'react';
import { NPLoadingBar } from '../../../comp/NPLoadingBar';
import FileAPI from '../../../api/FileAPI';
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow';
import NPButton from '../../../comp/NPButton';

/**
 * This card shows the uploaded documents of the customer
 */
export function CustomerDocSummaryCard({ profile }) {

    const [frontImgSrc, setFrontImgSrc] = useState()
    const [backImgSrc, setBackImgSrc] = useState()
    const [visuraCameraleDoc, setVisuraCameraleDoc] = useState()
    const [loading, setLoading] = useState()

    let docType = "Carta di Identità"
    if (profile && profile.docType == 'passport') docType = "Passaporto";
    if (profile && profile.userType == 'company') docType = 'Visura Camerale'

    const loadPhotos = async () => {

        if (!profile) return;

        setLoading(true)

        if (profile.userType != 'company') {
            const frontImage = await new FileAPI().downloadIDPhotoStream('front');
            const backImage = await new FileAPI().downloadIDPhotoStream('back');

            setFrontImgSrc(frontImage)
            setBackImgSrc(backImage)
        }
        else {
            const visuraDoc = await new FileAPI().downloadVisuraStream();

            setVisuraCameraleDoc(visuraDoc);

        }

        setLoading(false)
    }

    useEffect(() => { loadPhotos() }, [])

    if (!profile) return (<></>)

    return (
        <NPCard>
            <div className="card-title">I tuoi documenti</div>
            <div className="customer-doc-summary-card">
                <div className="info">
                    <InfoSVG />
                    <p>Pleggit conserva i tuoi documenti al fine di ottemperare agli obblighi di legge. Per più informazioni leggi la nostra <a href="https://pleggit.com/privacy" target="_blank"><b>Informativa Privacy</b></a>.</p>
                </div>
                <HSpacer size="large" />
                <div className='doc-container'>
                    <div className='headline'>
                        Hai salvato 1 documento
                    </div>
                    <HSpacer />
                    <NPLabeledValue label="Tipo Documento" value={docType} />
                    <HSpacer />
                    <div className='photo-container'>
                        <div className='label'>Documento</div>
                        {loading && <NPLoadingBar label="Caricamento documento in corso.." />}
                        {profile.userType != 'company' &&
                            <ResponsiveRow>
                                <img src={frontImgSrc} />
                                {profile && profile.docType != "passport" && <img src={backImgSrc} className='space-left' />}
                            </ResponsiveRow>
                        }
                        {profile.userType == 'company' && <a href={visuraCameraleDoc} target='_blank'>Link alla Visura Camerale</a>}
                    </div>
                </div>
            </div>
        </NPCard>
    )

}