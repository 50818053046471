import { useEffect, useState } from 'react'
import './CustomerDocUploadCard.css'
import FileAPI from '../../../api/FileAPI'
import ProfileAPI from '../../../api/ProfileAPI'
import NPCard from '../../../comp/card/NPCard'
import { HSpacer } from '../../../comp/layout/HSpacer'
import NPSelect from '../../../forms/NPSelect'
import { IdentityCardExamplePaper } from './IdentityCardExamplePaper'
import { IdentityCardExamplePaperInside } from './IdentityCardExamplePaperInside'
import { IdentityCardExampleElBack, IdentityCardExampleElFront } from './IdentityCardExampleEl'
import { IdentityCardSelector } from './IdentityCardSelector'
import { ResponsiveRow } from '../../../comp/layout/ResponsiveRow'
import { PassportExample } from './PassportExample'
import NPPhotoUploader from '../../../forms/NPDocUploader'

const DOCS = [
    { value: "ci", label: "Carta di Identità" },
    { value: "passport", label: "Passaporto" }
]

/**
 * 
 * @param {function} onCompleted callback to use when the upload has been completed
 * @returns 
 */
export function CustomerDocUploadVisura({ onCompleted }) {

    const [docType, setDocType] = useState()
    const [ciType, setCiType] = useState()

    const [loadingVisura, setLoadingVisura] = useState(false)
    const [visura, setVisura] = useState()

    const [uploadedVisura, setUploadedVisura] = useState(false)

    /**
     * Function to upload the file
     * 
     * @param {file} file the file selected for upload
     */
    const onVisuraUpload = async (file) => {

        setLoadingVisura(true)

        await new FileAPI().uploadVisuraCamerale(file);

        // Pass back the photo link
        await new ProfileAPI().updateProfile({
            visuraCameraleLink: "visuraCamerale",
        })

        // Load the photo
        // const imgSrc = await new FileAPI().downloadVisuraStream();

        // Update the state with the picture
        // setFrontImage(imgSrc)
        setLoadingVisura(false)
        setUploadedVisura(true)

    }

    useEffect(() => {

        if (uploadedVisura && onCompleted) onCompleted();

    }, [uploadedVisura])

    return (

        <NPCard>
            <div className='customer-doc-upload-card'>

                <div className="card-title">Caricamento documento</div>
                <div className="instructions">
                    Carica la Visura Camerale della Società.<br />
                </div>

                <HSpacer />
                <div className='upload-box'>
                    {/* <div className='label'>Carica una foto del tuo <b>Passaporto</b>, come mostrato nell'esempio.</div> */}
                    {/* <div className='details'><i>E' sufficiente caricare la foto della pagina in cui sono riportati i dati anagrafici, come mostrato nell'esempio qui sotto.</i></div> */}
                    <ResponsiveRow>
                        <div style={{ marginRight: 12 }}>
                            <NPPhotoUploader uploading={loadingVisura} onChange={onVisuraUpload} />
                        </div>
                    </ResponsiveRow>
                </div>
            </div>
        </NPCard>
    )

}
